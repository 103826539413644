exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-ai-and-stolen-joy-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/ai-and-stolen-joy/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-ai-and-stolen-joy-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-circuit-breaker-and-sidecar-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/circuit-breaker-and-sidecar/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-circuit-breaker-and-sidecar-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-normalized-selfish-human-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/normalized-selfish-human/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-normalized-selfish-human-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-samhuiri-supulro-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/samhuiri-supulro/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-samhuiri-supulro-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-tgl-golf-teams-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/tgl-golf-teams/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-tgl-golf-teams-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-thyroid-and-unconscious-stress-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/thyroid-and-unconscious-stress/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-thyroid-and-unconscious-stress-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-what-is-nankai-trough-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/what-is-nankai-trough/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-what-is-nankai-trough-index-mdx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-with-a-thud-index-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/contents/posts/with-a-thud/index.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-js-content-file-path-src-contents-posts-with-a-thud-index-mdx" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-tag-posts-js": () => import("./../../../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

